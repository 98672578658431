.slash {
    font-size: 11px;
    margin-left: -1px;
}
.m {
    font-size: 12px;
    padding-top: 3px;
}
.z-top {
    position: relative;
    z-index: 9;
}

.nav1 {
    z-index: 9;
    pointer-events: none;
    position: relative;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 10px;
    letter-spacing: 1px;
    font-weight: 700;
    text-transform: uppercase;
    transform: rotate(270deg);
    padding: 10px;
    margin-left:-10px;
    margin-top: 150px;
    text-decoration: none;
    color: #254f3f85;
}

.nav1::after {
    content: '';
    position: absolute;
    bottom:6px;
    left: 6px;
    width: 72%;
    height: 2px;
    background-color: #1632277f;
}

.nav2 {
    /* coming soon */
    z-index: 9;
    position: relative;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 10px;
    letter-spacing: 1px;
    font-weight: 700;
    text-transform: uppercase;
    transform: rotate(270deg);
    padding: 10px;
    margin-left:-10px;
    margin-top: 70px;
    text-decoration: none;
    color: #254f3f;
}
.nav2::after {
    content: '';
    position: absolute;
    bottom:6px;
    left: 8px;
    width: 78%;
    height: 2px;
    background-color: #163227;
}

.nav3 {
        /* coming soon */
        pointer-events: none;

    z-index: 9;
    position: relative;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 10px;
    letter-spacing: 1px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 80px;
    transform: rotate(270deg);
    padding: 10px;
    margin-left:-10px;
    text-decoration: none;
    color: #254f3f;
    color: #254f3f7c;  

}
.nav3::after {
    content: '';
    position: absolute;
    bottom:6px;
    left: 6px;
    width: 80%;
    height: 2px;
    background-color: #163227;
    background-color: #254f3f7c;
}

.navLoc {
        /* coming soon */
        pointer-events: none;

    position: relative;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 8px;
    letter-spacing: 1px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 170px;
    transform: rotate(270deg);
    padding: 10px;
    margin-left:-10px;
    text-decoration: none;
    color: #254f3f; 
}
/* .navLoc::after {
    content: '';
    position: absolute;
    bottom:6px;
    left: 5px;
    width: 80%;
    height: 1px;
    background-color: #163227;
} */
/* .nav-icon {
    margin-right:200px;
} */

@media (max-width: 700px) {
    .nav1 {
        margin-top: 70px;
        margin-left: -70px;
    }
    .nav2 {
        margin-top: 60px;
        margin-left: -70px;
    }
    .nav3 {
        margin-top: 63px;
        margin-left: -70px;
    }
    .navLoc {
        margin-top: 140px;
        margin-left: -60px;
    }
}