
body {
  font-family: "Cormorant Garamond", serif;
  background-color: #F3F2F1;
  color: #273828;
  overflow-x: hidden;
  margin: 0;
}


.scroll-down {
  position: absolute;
  font-size: 1.5vw;
  bottom: 10px;
  left:45%;
  right: 45%;
  font-weight: 100 !important;
}

:root {
  --form-control-color: #5f715c;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}



body {
  margin: 0;
}

.together-in-the-woods {
  font-weight: 700;
  color:#273828;
}

.top-right-date.top-right-date {
  position: fixed;
  right: 50px;
  top: 20px;
}
.ten {
  font-weight: 300;
  font-size: 10px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.slash2 {
  font-size: 10px;
  padding-top: 5px;
  margin-left: -1px;
}
.m2 {
  font-weight: 300;
  font-size: 10px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  padding-top: 5px;
}
form {
  display: grid;
  place-content: center;
  min-height: 100vh;
}

.overflow-hidden {
  position: relative;
  height: 100vh;
  width:100vw;
  overflow-x: hidden;
  overflow-y: hidden;
}

.leave-message {
  position: absolute;
}

.mtns-rsvp {
  position: absolute;
  top:460px;
  width: 90vw;
  z-index: -2;
  filter:hue-rotate(250deg) grayscale(70%)
}

.form-control {
  font-family: system-ui, sans-serif;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}

.form-control + .form-control {
  margin-top: 1em;
}

.form-control:focus-within {
  color: var(--form-control-color);
}

input[type="radio"] {
  cursor: pointer;
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: none;
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: #333333;
  width: 1.35em;
  height: 1.35em;
  border: 0.06em solid #333333;
  border-radius: 20%;
  transform: translateY(-0.75em);

  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  cursor: pointer;
  width: 0.95em;
  height: 0.95em;
  border-radius: 20%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="radio"].no::before {
  content: "";
  cursor: pointer;
  width: 0.95em;
  height: 0.95em;
  border-radius: 20%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #9e715c;
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

input[type="radio"]:focus {
  outline: max(.5px, 0.07em) solid #333333;
  outline-offset: max(.5px, 0.07em);
}

.nav-icon {
  margin-top: 20px;
}

.parallax-container{
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  perspective: 300px;
}

.justify-center {
  justify-content: center;
}

.parallax-group {
  position: relative;
  min-height: 100vh;
  transform-style: preserve-3d;
  .parallax-layer {
    position: absolute;
    inset: 0;
  }
  .base-layer {
    position: absolute;
    margin-top: 240px;
    left: 27%;
    transform: translateZ(0px) scale(1);
    z-index: 10;
  }
  .photo-layer {
    position: absolute;
    margin-top: 200px;
    left: 27%;
    transform: translateZ(80px) scale(1.1);
    z-index: 10;
  }
  .photo-layer2 {
    position: absolute;
    margin-top: 640px;
    left: 27%;
    transform: translateZ(0px) scale(1.6);
    z-index: 0;
  }
  .photo-layer-second {
    position: absolute;
    margin-top: 480px;
    left: 33%;
    transform: translateZ(-40px) scale(1.7);
    z-index: 10;
  }
  .photo-layer2-second {
    position: absolute;
    margin-top: 1040px;
    left: 27%;
    transform: translateZ(40px) scale(1.4);
    z-index: 0;
  }
  .photo-layer2-second2 {
    position: absolute;
    margin-top: 1080px;
    left: 33%;
    transform: translateZ(-100px) scale(1.9);
    z-index: 0;
  }
  .location-h1 {
    margin-top: 1800px;
    margin-left: 45%;
  }
  .come-back {
    font-weight: 500;
    font-size: 1.3vw;
    margin-top: 2350px;
    margin-left: 45%;
    height: 200px;
  }
  .mid-layer {
    transform: translateZ(0)
  }
  .top-layer {
    position: absolute;
    right: 600px;
    transform: translateZ(-1510px) scale(5);
    z-index: 0;
  }
  .parallax-img {
    width: 1500px;
    margin-top: 120px;
    transition: .2s;
    filter:hue-rotate(250deg) grayscale(70%)
  }  
  
}

.black {
  color: #333333;
}

.top {
  z-index: 9;
}

.card {
  padding: 10px;
  width: 20vw;
}


.card-right {
  position: relative;
  margin-left: 40%;
  margin-top: -80px;
  padding: 10px;
  width: 18vw;
  z-index: 100;
}

.photo-width {
  width: 32vw;
}
.photo-width2 {
  width: 20vw;
}

.photo {
  position: relative;
  margin-left: -50px;
  padding: 10px;
  width: 240px;
  z-index: 1;
}

.photo-right {
  margin-left: 30%;
  margin-top: -100px;
  padding: 10px;
  width: 240px;
  z-index: 0;
}


.rsvp {
  pointer-events: auto;
  position: absolute;
  font-size: 2vw;
  z-index: 19;
  margin-top:40px;
}

.caption {
  position: absolute;
  font-size: .6vw;
  z-index: 9;
  margin-top:-20px;
  text-decoration: underline;
}
.caption-light {
  margin-left: 13px;
  font-weight: 200;
}

.parallax-container

.body-background {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  bottom:0;
  background-image: url('./mtns.png');
  filter: grayscale(100%);
  background-size: cover;
  height: 100vh;
  transition: all .5s ease;
}
.body-background:hover {
  filter: blur(0px);
}

.z-top {
  position: relative;
  z-index: 9999;
}

.link {
  text-decoration: none;
  color: #333333;
}

.hover-grow {
  cursor: pointer;
  transition:all .5s ease;
}

.hover-grow:hover {
  /* transform: scale(1.3); */
}

.page-title-blur {
  filter: blur(0px);
  transition: all .5s ease;
}
.page-title-blur:hover {
  filter: blur(0px);
}

.blur {
  filter: blur(.2px);
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.top-0 {
  top:0;
}

.left-0 {
  left:0;
}

.bottom-0 {
  bottom: 0;
}

.height-100 {
  height: 100%;
}

.font-size-h1 {
  font-size: 10vw;
  font-weight: 400;
  letter-spacing: 0px;
}

.font-size-p {
  font-size: 1.3vw;
  line-height: 1.8em;
}

.font-size-small {
  font-size: 1vw;
}
.no-guests-label {
  margin-top:-25px;
  margin-bottom: -45px;
}

.no-guests-input {
  width: 13%;
  text-align: center;
  margin-left: 12%;
  padding: 10px;
  padding-bottom: 5px;
  background-color: #f3f2f100;
  border: none;
  border-bottom: 1px solid #333333;
  transition: all .1s ease-out;
  margin-bottom: 25px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.glass-textarea {
  height: 100px;
  border: 1px solid white;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  background-color: #ffffff6f;
  box-shadow: 0px 0px 10px #a4a4a460;
  vertical-align: top;
  padding:15px;
  font-size: 1.1vw;
  z-index: 0;
  resize: none;
}

textarea:focus {
  outline: 2px solid #5f715c;
}

.submit-font {
  font-size: 20px;
}


.submit-btn {
  background-color: #ffffff00;
  border: none;
  cursor: pointer;
  transition: all .1s ease-in-out;
}

.submit-btn:focus {
  outline: none;
  margin-left: 2px;
  transform: skewX(-10deg);
}

.mail-svg {
  position: absolute;
  bottom: -20px;
  padding:0;
  height: 95px;
  width: 30%;
  animation-play-state: paused;
}

#swoop {
  margin-left:5px;
}

#paperplane {
  right:-60px;
  top:10px;
  position: absolute;
  width:100px;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 1.1s cubic-bezier(.67,.22,.86,.66) forwards;
  animation-play-state: paused;
  transition: all .3s ease-out;
}

.mail-svg:focus .path{
  animation-play-state: running;

}

@keyframes dash {
  from {
    stroke-dashoffset: 998;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.no-guests-input:focus {
  outline: none;
  border-bottom: 3px solid #5f715c;
  margin-bottom: 23px;
}

.width-medium {
  margin-left: 10%;
  width: 80%;
}

.text-input {
  width: 75%;
  margin-left: 30px;
  padding: 10px;
  padding-bottom: 5px;
  background-color: #f3f2f100;
  border: none;
  border-bottom: 1px solid #333333;
  transition: all .1s ease-out;
  margin-bottom: 25px;
}
.text-input:focus {
  outline: none;
  border-bottom: 3px solid #5f715c;
  margin-bottom: 23px;
}
.width-50 {
  width: 50%;
}
.radio-input {
  margin: -12px 0 12px 90% !important;
}
.joyfully {
  margin-top: 12px;
}
.raleway {
  font-family: 'Raleway', sans-serif;
}

.light-weight {
  font-weight: 300;
}

.font-small {
  font-size: 11px;
}

.heavy-weight {
  font-weight: 700;
}

.flex {
  display: flex;
}

.width-100 {
  width: 100vw;
}

.margin-small {
  margin: 10px;
}

.no-events {
  pointer-events: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */

}

.text-align-right {
  text-align: right;
}

.margin-left-small {
  margin-left: 20%;
}

.col {
  flex-direction: column;
}

.just-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.margin-large {
  margin: 100px 200px;
}

.pad-large {
  padding: 100px 200px;
}

.pad-small {
  padding: 10px 20px;
}

.align-bottom {
  align-items: flex-end;
}

.pad-bottom-tiny {
  padding-bottom: 5px;
}

.pad-medium {
  padding: 20px 20px;
}

.text-center {
  text-align: center;
}

.rotate-90 {
  transform: rotate(270deg);
}

.underlined {
  text-decoration: underline;
}

.small-caps {
  text-transform: uppercase;
  font-size: 1vw;
  letter-spacing: 1px;
  font-family: "Raleway", sans-serif;
  margin-bottom: 20px;
}
.grey-dash {
  width: 3vw;
  border: 1.5px solid lightslategrey;
  margin-bottom: 20px;
}
.font-size-h3 {
  font-size: 4vw;
}

.debug-pink {
  background-color: pink;
}

.bottom-left-hr-rsvp {
  position: absolute;
  text-align: left;
  width: 34%;
  height: 1px;
  background-color: #333333;
  bottom: -1vw;
  border: none;
  margin-right: 0;
  color: #333333;
}

.top-right-hr-rsvp {
  text-align: right;
  width: 80%;
  height: 2px;
  background-color: #333333;
  border: none;
  margin-right: 0;
  color: #333333;
}

.text-align-left {
  text-align: left;
}

.font-size-h2 {
  font-size: 7vw;
}

.font-size-h4 {
  font-size: 2.6vw;
}

.padding-left-medium {
  padding-left: 30%;
}
.font-size-h5 {
  font-size: 1.8vw;
}

.font-size-h6 {
  font-size: 1.3vw;
}

.margin-top-small {
  margin-top: 30px;
}

.middle-hr-rsvp {
  position: absolute;
  background-color: #333333;
  border: none;
  right: 00px;
  width: 2px;
  height: 40vw;
}

.relative {
  position: relative;
}

.padding-medium {
  padding: 50px 100px
}

.padding-small {
  padding: 50px 80px
}

.nav-pull-margin {
  margin-left: 60px;
}

.space-between {
  justify-content: space-between;
}

.pointer-none {
  pointer-events: none;
}

.error-popup {
  text-align: center;
  position: fixed;
  border: 1px solid white;
  box-shadow: 0 0 5px #76767644;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.323);
  color: #9e715c;
  font-weight: 400;
  top: 50px;
  left: 32vw;
  z-index: 9;
  backdrop-filter: blur(10px);
  padding: 1vw 2vw 1vw;
  width: 36vw;
  font-size: 2vw;
}

.success-popup {
  text-align: center;
  position: fixed;
  border: 1px solid white;
  box-shadow: 0 0 5px #76767664;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.323);
  top: 50px;
  left: 32vw;
  z-index: 9;
  backdrop-filter: blur(15px);
  padding: 3.5vw 2vw 1vw;
  width: 36vw;
  font-size: 2vw;
}

.diet {
  margin-left: -20%;
  margin-top: 50px;
  width: 140%;
}

.return-address {
  position: absolute;
  top:0;
  right:50px;
}

input {
  border-radius: 0;
}

input[type="search"] {
  -webkit-appearance: none;
}

.mobile-bye {
  display: none !important;
}

@media (max-width: 700px) {
  .mobile-rsvp {
    position: absolute;
    width:100vw;
  }
  .mobile-return-address {
    position: absolute;
    top:0px;
    right: 20px;
  }
  .mobile-address-font {
    font-size: 2.2vw;
  }
  .mobile-middle-line {
    display: none;
  }
  .no-guests-mobile {
    margin-top:50px;
    margin-left: 0 !important;
    margin-right: 0;
    width: 100%;
    text-align: center;
    // margin-bottom: 290px;
  }
  .overflow-hidden {
    overflow-y: auto;
  }
  .glass-textarea {
    font-size: 2.6vw;
  }
  input[type="radio"] {
    transform: translateX(60px) translateY(-30px);

  }
  .nav-icon {
    margin-left:-60px;
  }
  .mtns-rsvp {
    top:690px;
  }
  .mobile-rsvp-header {
    font-size: 14vw;
  }
  .mobile-text-input {
    font-size:5vw;
    width: 100%;
    margin-right: 10px;
    min-width: 80px;
    flex-direction: column;
  }
  .mobile-rsvp-s {
    font-size:4vw;
  }
  .mobile-radio-input {
    width:50%;
    margin-top: 80px;
    margin-left: 5%;
    font-size: 6vw;
  }
  .textarea-mobile {
    font-size: 5vw;
    width:100% !important;
    margin-left:10%;
  }
  .mobile-diet {
    padding: 40px 0 60px;
    font-size: 5vw;
    min-width: 200px;
    margin-left: -30%;
  }
  .font-size-h4-mobile{
    font-size: 2.6vw;
  }
  // .bottom-left-hr-rsvp {
  //   bottom:20px;
  // }
}

@media (max-width:700px) {
  .the-big-day {
    margin-top: 18vh !important;
    font-size: 25vw;
  }
  .parallax-img {
    width: 250vw !important;
    margin-top: 300px !important;
    margin-left: -100vw !important;
  }
  .card.card {
    height: 300px;
    width: 300px;
  }
  .card-right.card-right {
    height: 300px;
    width: 60vw;
    margin-top: 120px;
    margin-left: 0%;
  }
  .landing-date-card {
    font-size: 10vw !important;
  }
  .small-caps {
    font-size: 4vw !important;
  }
  .rsvp-landing {
    font-size: 6vw;
  }
  .landing-desc {
    font-size: 4vw;
  }
  .grey-dash {
    width: 12vw;
  }

  // small screen photos
  .photo-width {
    width: 50vw;
  }
  .photo-width2 {
    width: 55vw;
  }
  .photo {
    position: relative;
    margin-top: 100px;
    margin-left: 0px;
    padding: 10px;
    z-index: 1;
  }
  
  .photo-right {
    margin-left: 0%;
    margin-top: -170px;
    padding: 10px;
    z-index: 0;
  }
  .photo-layer {
    position: absolute;
    margin-top: 390px !important;
    transform: translateZ(150px) scale(.8) !important;
    z-index: 10;
  }
  .photo-layer2 {
    position: absolute;
    margin-top: 850px !important;
    left: 23% !important;
    transform: translateZ(80px) scale(1.1) !important;
    z-index: 0;
  }
  .photo-layer2-second {
    position: absolute;
    margin-top: -50px !important;
    left: 20% !important;
    transform: translateZ(220px) scale(.5) !important;
    z-index: 0;
  }
  .photo-layer-second {
    position: absolute;
    margin-top: 320px !important;
    left: 32% !important;
    transform: translateZ(60px) scale(1.3) !important;
    z-index: 10;
  }
  
  .photo-layer2-second2 {
    position: absolute;
    margin-top: 300px !important;
    left: 30% !important;
    transform: translateZ(-100px) scale(1.9);
    z-index: 0;
  }
  .scroll-down {
    position: absolute;
    font-size: 6vw !important;
    bottom: 10px;
    left:42% !important;
    right: 42% !important;
  
  }
  .caption {
    background-color: #f3f2f1d6;
    backdrop-filter: blur(200px) !important;
    -webkit-backdrop-filter: blur(200px) !important;

    padding: 6px;
    border-radius: 5px;
    border: .5px solid white;
    margin-top: -10px;
    font-size: 1.8vw !important;
  }
  .location-h1 {
    margin-top: 1200px !important;
    margin-left: 45%;
  }
  .come-back {
    font-weight: 500;
    width: 200px;
    font-size: 7vw !important;
    margin-top: 1450px !important;
    margin-left: 45%;
    margin-bottom: 200px !important;
    height: 100px !important;
  }
  .mobile-bye {
    margin-top: 1750px !important;
    font-size: 7vw !important;
    display: inline-block !important;
  }
  .success-popup {
    width: 60vw;
    top:55px;
    font-size: 4vw;
    left: 20vw;
    padding: 5vw 5vw 1vw;
  }
  .error-popup {
    width: 60vw;
    top:55px;
    font-size: 4vw;
    left: 20vw;
    padding: 5vw 5vw 1vw;
  }
}

.mobile-itinerary {
  display: none;
}

.itinerary-h1 {
  position: absolute;
  top: 6vw;
  font-size: 10vw;
  left: 22vw;
  margin: 0;
}
.itinerary-div {
  position: absolute;
  top: 50px;
  left: 250px;
  width: 30vw;
  height: 44vw;
  background-color: #D8DED8;
  border-radius: 500px 500px 0 0;
  box-shadow: -3px 3px 10px rgba(0, 0, 0, 0.244);
}
.itinerary-div-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.itinerary {
  position: absolute;
  top: 60px;
  left: 2.5vw;
  width: 44vw;
}

.foil {
  width: 10vw;
  position: absolute;
  right: 15vw;
  top: 60px;
}
.foil2 {
  width: 34vw;
  position: absolute;
  top: 44vw;
  left: 452px;
  z-index: 9;
} 



@media (max-width:1300px) {
  .foil2 {
    top: 42vw;

  }
}

@media (max-width:1100px) {
  .itinerary {
    top: 20px;
    width: 72vw;
    height: 70vw;
  }
  .itinerary-div {
    left: 140px;
    width: 50vw;
    height: 70vw;
  }
  .foil {
    top: 400px;
  }
  .foil2 {
    top: 100px;
  }
}


@media (max-width: 600px) {
  .itinerary {
    top: 100px;
    left: -90px;
    height: 0vw;
  }
  .itinerary-div {
    display: none;
  }
  .foil {
    display: none;
  }
  .foil2 {
    display: none;
  }
  .mobile-itinerary {
    position: absolute;
    left: 0;
    top: 40px;
    left: 40px;
    display: flex;
    width: 100vw;
  }
}
.overflow-hidden {
  overflow-x: hidden;
  overflow-y: auto;
}
.countdown-container {
  font-family: "Cormorant Garamond", serif;
  width: 100vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.countdown-h1 {
  font-weight: 900;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

}
.countdown {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-weight: 500;
}